import { forwardRef } from 'react'

import PropTypes from 'prop-types'

interface LogoProps {
  scale: number
  color: string
  [key: string]: any
}

const Logo = forwardRef<SVGSVGElement, LogoProps>(({ scale, color, ...props }, ref) => (
  <svg
    ref={ref}
    style={{ transition: 'all 0.2s', transform: `scale(${scale})` }}
    width="96px"
    height="36px"
    viewBox="0 0 992 390.37"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fill={color}
      fillRule="nonzero"
      className="st0"
      d="M849,474.69c9.08,11.18,12.57,27.08,9.32,42.51a13.58,13.58,0,0,1-6.75,9.09,14.34,14.34,0,0,1-11.91.89c-19-7-51.25-4.38-88.59-1.37-23.07,1.86-46.93,3.78-71,3.78-17.44,0-30.4-4.19-38.53-12.46-6.6-6.7-9.85-15.8-9.66-27.05.72-43.89,69.78-184.86,140.14-286.48-11.9,2.89-49.8,11.36-85,11.36-19.77,0-35.32-5.86-46.23-17.44-10.34-11-15.71-26.64-14.75-43a10.63,10.63,0,0,1,4.38-7.95,11.45,11.45,0,0,1,9.61-1.84c24.69,6.24,66.28,2.75,110.29-1,26.63-2.23,54.16-4.54,77.91-4.54,10.19,0,18.48,4.37,22.77,12,4.06,7.22,3.71,16.09-.93,23.73-35.5,58.46-131.58,224.51-153.9,297,11.61-1.8,23.17-3.85,34.37-5.83,27.51-4.87,53.5-9.46,74.66-9.46C829.27,456.59,842.31,466.43,849,474.69ZM607.46,503.58a13.79,13.79,0,0,1-5,18.58,51.12,51.12,0,0,1-26.89,7.43c-20.6,0-34.76-11.67-39.27-32.18-16.62,21.07-38.75,32.18-64.21,32.18-33.74,0-62.38-17.26-82.81-49.91C370,448.9,359.4,405.6,359.4,357.75c0-128,63.36-217.48,154.07-217.48,35.61,0,62.63,11.52,76.09,32.43,9.5,14.77,11.19,33.21,4.4,48.12a9.22,9.22,0,0,1-7.87,5.38,9.5,9.5,0,0,1-8.73-4.31c-7.63-11.91-22.66-26.11-50.94-26.11-27.94,0-51.78,13.32-69,38.51-19.21,28.16-29.36,70.85-29.36,123.46,0,72.37,20.52,119.13,52.27,119.13,48.19,0,54.37-91,54.37-170.28,0-24.31-4-36.51-7.33-42.48a13.79,13.79,0,0,1,5-18.57,51.15,51.15,0,0,1,26.89-7.43c25.57,0,40.83,17.73,40.83,47.44V461.11c0,24.3,4,36.51,7.32,42.47Z"
      transform="translate(-98.5 -139.22)"
    />
    <path
      fill={color}
      fillRule="nonzero"
      className="cls-1"
      d="M338.52,368.54c-6.43-10.28-22.14-6.7-23.58,5.34C309.2,422,286.55,476.53,239.49,476.53c-41,0-63.42-43-71-93.46,202.12-9.56,212.92-242.8,72.65-242.8-91.86,0-142.61,96.89-142.61,192.6,0,105.92,49,196.32,140.08,196.32C338.46,529.19,369.94,418.86,338.52,368.54ZM240.1,192.93c48,0,42.2,121.63-74.62,147.47C165.94,270.72,194.82,192.93,240.1,192.93Z"
      transform="translate(-98.5 -139.22)"
    />
    <path
      fill={color}
      fillRule="nonzero"
      className="cls-1"
      d="M1078.81,368.54c-6.42-10.28-22.14-6.7-23.57,5.34-5.74,48.07-28.4,102.65-75.45,102.65-41,0-63.43-43-71-93.46,202.12-9.56,212.91-242.8,72.65-242.8-91.86,0-142.62,96.89-142.62,192.6,0,105.92,49,196.32,140.08,196.32C1078.76,529.19,1110.23,418.86,1078.81,368.54ZM980.39,192.93c48,0,42.2,121.63-74.61,147.47C906.23,270.72,935.12,192.93,980.39,192.93Z"
      transform="translate(-98.5 -139.22)"
    />
  </svg>
))

Logo.displayName = 'Logo'

Logo.propTypes = {
  color: PropTypes.string,
  scale: PropTypes.number
}

export default Logo
